.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: "Montserrat", sans-serif !important;
}

.custom-padding {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
}

@media (max-width: 767px) {
    .custom-padding {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
    }
}

@media (min-width: 768px) and (max-width: 1023px) {
    .custom-padding {
        padding-left: 1rem;
        padding-right: 1rem;
    }
}

@media (min-width: 1024px) {
    .custom-padding {
        padding-left: 0.8rem;
        padding-right: 0.8rem;
    }
}

.bg-app {
    /* rgba(240, 240, 240, 0.51) */
    background: rgba(225, 224, 224, 0.51) none repeat scroll 0% 0%;
}

.App {
    text-align: center;
    font-family: "Montserrat", sans-serif !important;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.account-pages .logo-admin {
    position: absolute;
    left: 0;
    right: 0;
    margin: 0px auto;
    width: 107px;
    height: 74px;
    line-height: 74px;
    background: #fff;
    border-radius: 50%;
    border-bottom: none;
    margin-top: 5px;
    padding-top: 20px;
}

.umo-logo {
    max-width: none;
    width: 110px;
    border-radius: 4px;
}

.bg-red {
    background-color: #c70707 !important;
    border-color: #c70707 !important;
    color: white;
}

.bg-yellow {
    background-color: #e0f309 !important;
    border-color: #e0f309 !important;
    color: black !important;
    font-weight: bold !important;
}

.bg-green {
    background-color: #53a140 !important;
    border-color: #53a140 !important;
    color: white;
}

.bg-blue {
    background-color: #284287 !important;
    border-color: #284287 !important;
    color: white;
}

.bg-purple {
    background-color: #663399 !important;
    border-color: #663399 !important;
    color: white;
}

.bg-purple-200 {
    background-color: #816e94 !important;
    border-color: #816e94 !important;
    color: white;
}

.bg-orange {
    background-color: #ff9933 !important;
    border-color: #ff9933 !important;
    color: white;
}

.text-purple {
    color: #284287 !important;
}

.btn-primary-purple-umo {
    background-color: #602a96;
    color: white;
    z-index: 0;
}

.btn-primary-purple-umo:hover {
    background-color: #602a96a3;
    color: white;
    z-index: 0;
}

.btn-primary-blue-umo {
    background-color: #284287;
    color: white;
    z-index: 0;
}

.btn-primary-red-umo {
    background-color: red;
    color: yellow;
    z-index: 0;
}

.navbar-brand-box {
    background-color: white !important;
    padding: 0 1.5rem;
    text-align: center;
    width: 240px;
}

.navbar-brand-box > img {
    margin-top: 1.75rem;
    padding-bottom: 1.75rem;
}

#sidebar-menu {
    padding: 0 0 30px 0;
}

.vertical-menu {
    overflow-y: auto;
    overflow-x: hidden;
    height: 100vh;
    position: inherit !important;
    background: #639 !important;
    color: white !important;
    box-shadow: 0px 13px 0px 0px rgba(236, 236, 241, 0.44) !important;
    z-index: 0;
}

#sidebar-menu ul li a {
    display: block;
    padding: 12px 20px;
    font-family: "Montserrat", sans-serif !important;
    position: relative;
    font-size: 15.7px;
    transition: all 0.4s;
    text-align: left;
}

body[data-sidebar="dark"] #sidebar-menu ul li a {
    color: white;
}

body[data-sidebar="dark"] #sidebar-menu ul li a i {
    color: white;
}

body[data-sidebar="dark"] #sidebar-menu ul li ul.sub-menu li a {
    color: white;
    background: transparent;
}

.nav-bar {
    height: 6.25rem;
}

.profile-settings {
    border: 1px solid #8080801a;
}

.card {
    margin-bottom: 0 !important;
    box-shadow: 0px 0px 0px 0px white !important;
    padding: 1.5rem 1.5rem 1.5rem;
    margin-bottom: 0 !important;
    box-shadow: 0px 0px 0px 0px white !important;
}

.card-body {
    padding: 0rem;
}

.form-control {
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    z-index: 0;
}

.form-control-vales {
    display: block;
    width: 300px;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    z-index: 0;
}

.form-control-inputs {
    padding: 0.375rem 0.75rem;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    z-index: 0;
}

.form-control-inputs-vales {
    padding: 0.375rem 0.75rem;
    font-size: 0.875rem;
    width: 130px;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    z-index: 0;
}

.form-control-inputs-vales-amarillo {
    padding: 0.375rem 0.75rem;
    font-size: 0.875rem;
    width: 130px;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #ffd900;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    z-index: 0;
}

.form-control-checkbox {
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    z-index: 0;
}

.form-control-select {
    /* display: block; */
    /* width: 100%; */
    /* height: calc(1.5em + 0.75rem + 2px); */
    /* padding: 0.375rem 0.75rem; */
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    z-index: 0;
}

.form-control-selects {
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    z-index: 0;
}

.badge-info {
    background-color: #e9edf033;
    color: inherit;
}

.col-form-label {
    padding-bottom: 0;
    height: 3rem;
    line-height: 1rem;
    padding-top: 8px;
    font-weight: bold;
}

.btn:hover {
    color: white;
}

/* input[type="file"] {
  color: rgba(0, 0, 0, 0);
} */

.btn-primary-orange-umo {
    background: #ff9933;
    color: white;
    font-weight: bold;
}

.table thead th {
    font-weight: bold;
}

/* .table thead th {
  font-weight: bold;
  text-align: center;
} */
/* 
.table tbody td {
  text-align: center;
} */

.table-hover tbody tr:hover {
    color: rgb(21, 20, 68);
    background-color: inherit;
}

.table-hover tbody tr:nth-child(odd) {
    color: #01050a;
    background-color: #f8f9fa;
}

a:hover {
    cursor: pointer;
}

.fc-button-active {
    z-index: 0 !important;
}

.sweet-alert p {
    z-index: 0 !important;
}

/* Hide scrollbar for Firefox */
/* .react-select__menu-list {
  scrollbar-width: none;
} */

/* Hide scrollbar for Chrome, Safari, etc. */
/* .react-select__menu-list::-webkit-scrollbar {
  display: none;
} */

/* .suggestions {
  border: 1px solid #999;
  border-top-width: 0;
  list-style: none;
  margin-top: 0;
  max-height: 143px;
  overflow-y: auto;
  padding-left: 0;
  z-index: 2;
}

.suggestions li {
  padding: 0.5rem;
}

.suggestion-active,
.suggestions li:hover {
  background-color: #d6d6d6;
  cursor: pointer;
}

.suggestions li:not(:last-of-type) {
  border-bottom: 1px solid #999;
} */

/* Estilos para calendario */

/* Estilo para vista dia */

.fc-timegrid.fc-timeGridDay-view.fc-view .fc-scrollgrid-section.fc-scrollgrid-section-body.fc-scrollgrid-section-liquid tr {
    height: 3.5rem !important;
}

/* Estilo para vista semana */

.fc-timegrid-slots table tbody tr td {
    height: 7rem !important;
}

.btn-fixed {
    position: fixed;
    bottom: 50;
    right: 100;
    margin-bottom: 10px;
    z-index: 0;
}

.Option {
    width: 100%;
}


/* Drawer  */

/* Calendario */

@media screen and (max-width: 767px) {
    .fc-toolbar.fc-header-toolbar {
        font-size: 75%;
        display: flex !important;
        flex-direction: column !important;
    }

    .fc-view {
        height: 60vh;
    }

    textarea {
        width: 100% !important;
        height: 200px !important;
    }
}

.grecaptcha-badge {
    visibility: hidden;
}

.logowifi{
    width: 40px;
    height: 30px;
    align-items: center;
    justify-content: center;
    margin: 0 10px;

} 

.Filtros{
    align-items: center;
    justify-content: center;
    vertical-align: middle;
    justify-content: center;
}
 
.form-control-disabled{
    display: block;
    width: 100%;
    height: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #000000;
    background-color: rgb(244, 244, 244);
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;  
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
  
  .my-swal {
    z-index: 10000;
  }

  .disabled-link {
    pointer-events: none;
    color:transparent;
    background-color: transparent;;
  }

  .celdaAutorizaciones{
    align-items: center;
    justify-content: center;
    text-align: center;
  } 

  .fixed-width-100 {
    width: 100px; /* Cambia el valor según lo que necesites */
  }

  .fixed-width-150 {
    width: 150px; /* Cambia el valor según lo que necesites */
  }  

  .fixed-width-200 {
    width: 200px; /* Cambia el valor según lo que necesites */
  }  

    .chartBox {
    padding: 10px;
    border-radius: 30px;
    border: solid 3px rgb(224, 228, 231);
    background: white;
    }

    .fc-event-title {
        text-align: left;  /* Alinea el título a la izquierda */
        white-space: normal;  /* Permite que el texto se divida si es largo */
        word-wrap: break-word; /* Hace que el texto largo se divida */
        overflow: visible; /* Permite que el contenido se vea completo */
      }

      .fc-event {
        min-height: 30px;  /* Ajusta la altura mínima de los eventos */
        height: auto;  /* Deja que la altura se ajuste según el contenido */
      }

      
/* .profile-settings {  
  z-index: 10000 !important; 
} */
